

























































































import {
  computed,
  createComponent,
  ref,
  onMounted,
  onBeforeUnmount
} from '@vue/composition-api'
import moment from 'moment'

import { Driver, getDrivers } from '~/models/driver'
import { Order, getOrders } from '~/models/order'
import { Vehicle, getAllVehicles } from '~/models/vehicle'

type Schedule = {
  pickup_time: string
  dropoff_time: string
  pickup_place: string
  dropoff_place: string
  user_name: string
  passengers: number
  price: number
  remarks: string
  vehicle_id: number
  vehicle_name: string
}
type Row = {
  seq: number
  pickup_time: string
  dropoff_time: string
  pickup_place: string
  dropoff_place: string
  user_name: string
  passengers: number
  price: number
  remarks: string
}

type DriverSchedule = {
  vehicle_id: number
  vehicle_name: string
  license_plate: string
  row: Row[]
}

type DateType = {
  day_of_week: string
  work_date: string
}

export default createComponent({
  setup(_props, context) {
    // define some constants
    const drivers = ref<Array<Driver>>([])
    const vehicles = ref<Vehicle[]>([])
    const driver_name = ref<string>(
      context.root.$auth.user.last_name + context.root.$auth.user.first_name
    )
    const current_vehicle = ref<number>(0)
    const driverSchedules = ref<DriverSchedule[]>([])
    const workdays = ref<Array<DateType>>([''])
    const headers = ref<Array<any>>([
      { text: '回', value: 'seq', width: '1%', align: 'center' },
      {
        text: '乗車時刻',
        value: 'pickup_time',
        width: '9%',
        align: 'center'
      },
      {
        text: '降車時刻',
        value: 'dropoff_time',
        width: '9%',
        align: 'center'
      },
      {
        text: '乗車場所',
        value: 'pickup_place',
        width: '20%',
        align: 'center'
      },
      {
        text: '降車場所',
        value: 'dropoff_place',
        width: '20%',
        align: 'center'
      },
      { text: '利用者名', value: 'user_name', width: '16%', align: 'center' },
      { text: '乗車人数', value: 'passengers', width: '10%', align: 'center' },
      { text: '料金', value: 'price', width: '6%', align: 'center' },
      { text: '備考', value: 'remarks', width: '9%', align: 'center' }
    ])

    const isDataTableLoading = ref<boolean>(false)

    const isPrintable = computed(() => {
      return driverSchedules.value.length > 0
    })

    /**
     * check update when date is selected or data is refreshed.
     */
    const checkUpdate = () => {
      if (!isDataTableLoading.value) {
        fetchGetOrders()
      }
    }

    // fetch all getOrders
    const fetchGetOrders = async () => {
      isDataTableLoading.value = true
      try {
        const orders: Order[] = await getOrders(context.root.$axios, date.value)
        const schedule = orders
          .filter(
            (order) =>
              (current_vehicle.value === 0 && order.vehicle_id != null) ||
              order.vehicle_id === current_vehicle.value
          )
          .map((order) => {
            return {
              pickup_time: fixTimeFormat(order.pickup_earliest),
              dropoff_time: fixTimeFormat(order.dropoff_latest),
              pickup_place: order.pickup_planned_location_name,
              dropoff_place: order.dropoff_planned_location_name,
              user_name: order.user_name,
              passengers: order.price.tickets.length,
              price: order.price.total,
              remarks:
                order.current_status === 'NOT_APPEARED' ? 'スキップ' : '',
              vehicle_id: order.vehicle_id,
              vehicle_name: order.vehicle_name
            } as Schedule
          })

        const sortedSchedule = schedule.sort((a, b) => {
          if (a.vehicle_name > b.vehicle_name) return 1 // 昇順
          if (a.vehicle_name < b.vehicle_name) return -1
          if (a.pickup_time > b.pickup_time) return 1 // 昇順
          if (a.pickup_time < b.pickup_time) return -1
          if (a.dropoff_time > b.dropoff_time) return 1 // 昇順
          if (a.dropoff_time < b.dropoff_time) return -1
          return 0
        })
        let vehicleId = 0
        let vehicleName = ''
        let licensePlate = ''
        let i = 0
        driverSchedules.value = []
        let list = [] as Row[]
        sortedSchedule.forEach((s, index) => {
          if (s.vehicle_id !== vehicleId) {
            if (list.length > 0) {
              const obj = {
                vehicle_id: vehicleId,
                vehicle_name: vehicleName,
                license_plate: licensePlate,
                row: list
              } as DriverSchedule
              driverSchedules.value.push(obj)
            }
            vehicleId = s.vehicle_id
            const [vehiclesById] = vehicles.value.filter(
              (v) => v.id === vehicleId
            )
            vehicleName = vehiclesById.name
            licensePlate = vehiclesById.license_plate
            i = 1
            list = []
          }

          const row = {
            seq: i,
            pickup_time: s.pickup_time,
            dropoff_time: s.dropoff_time,
            pickup_place: s.pickup_place,
            dropoff_place: s.dropoff_place,
            user_name: s.user_name,
            passengers: s.passengers,
            price: s.price,
            remarks: s.remarks
          }
          list.push(row)
          if (index === schedule.length - 1) {
            const obj = {
              vehicle_id: vehicleId,
              vehicle_name: vehicleName,
              license_plate: licensePlate,
              row: list
            } as DriverSchedule
            driverSchedules.value.push(obj)
          }
          i += 1
        })
      } catch (e) {
        console.error(e)
      }
      isDataTableLoading.value = false
    }

    const matchedDates = computed(() => {
      return date.value === moment().format('YYYY-MM-DD')
    })

    const fixTimeFormat = (time: any) => {
      return moment(time).format('HH:mm')
    }

    const date = ref(moment().format('YYYY-MM-DD'))

    // repeat reading
    const intervalMilliSecond = 5000
    const fetchingInterval = ref(0)
    onMounted(() => {
      fetchingInterval.value = window.setInterval(async () => {
        await fetchGetOrders()
      }, intervalMilliSecond)
    })
    onBeforeUnmount(() => {
      clearInterval(fetchingInterval.value)
    })
    const vehicle_lists = computed(() => {
      const values: any = [{ text: 'すべて', value: 0 }]
      vehicles.value.forEach((v) => {
        // if (v.driver_id === current_driver.value) {
        const vv = {
          text: v.name,
          value: v.id
        }
        values.push(vv)
        // }
      })
      values.sort((a: any, b: any) => {
        return a.value - b.value
      })
      return values
    })

    const printDocument = () => {
      const newWindow = window.open(
        'Print',
        '_blank',
        'width=800,height=500,resize=0'
      )
      newWindow!.document.body.innerHTML = document.getElementById(
        'printable'
      )!.innerHTML
      const sheet = document.querySelector('.sheet')
      if (sheet !== null) {
        const divElement = sheet as HTMLDivElement
        // 1頁内に明細を30行（改行無し）を印字できるよう自動で倍率を設定
        divElement.style.transform = 'scale(0.98)'
      }
      newWindow!.window.print()
      newWindow!.close()
    }

    const workingDays = (val: string) => {
      const days: string[] = []
      for (let i = 0; i < workdays.value.length; i++) {
        const day = moment(workdays.value[i].work_date)
        days.push(day.format('YYYY-MM-DD'))
      }
      return days.includes(val)
    }

    onMounted(async () => {
      isDataTableLoading.value = true
      const [resDrivers, resVehicles] = await Promise.all([
        getDrivers(context.root.$axios).catch(() => []),
        getAllVehicles(context.root.$axios).catch(() => {
          logout()
        })
      ])
      const logout = () => {
        context.root.$router.push({
          path: '/login/'
        })
      }
      drivers.value = resDrivers
      vehicles.value = resVehicles
      await fetchGetOrders().catch(() => [])

      const allowDays = 2
      workdays.value = await context.root.$axios
        .get(`/holidays/workdays?allowDays=${allowDays}`)
        .then((res: any) => {
          return res.data
        })
        .catch(() => {
          const days = []
          const WEEKS = ['日', '月', '火', '水', '木', '金', '土']
          for (let i = 0; i <= allowDays; i++) {
            const day = moment().add(i, 'days')
            if (day.day() !== 0 && day.day() !== 6) {
              const date: DateType = {
                day_of_week: WEEKS[day.day()],
                work_date: day.format('YYYY-MM-DD')
              }
              days.push(date)
            }
          }
          return days
        })
    })

    return {
      headers,
      isPrintable,
      date,
      driver_name,
      current_vehicle,
      drivers,
      vehicles,
      vehicle_lists,
      driverSchedules,
      fetchGetOrders,
      fixTimeFormat,
      isDataTableLoading,
      matchedDates,
      checkUpdate,
      printDocument,
      workingDays,
      workdays
    }
  },

  auth: false
})
