



























































































































































































































































































































































































































































import {
  computed,
  createComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  SetupContext
} from '@vue/composition-api'
import NoSleep from '~/node_modules/nosleep.js'
import moment from '~/node_modules/moment'
import ButtunOperatorComponent from '@/components/common/buttun_operator.vue'
import CarSelectClearCompornent from '@/components/pages/carselect_clear.vue'
import DailyReportComponent from '@/components/pages/daily_report.vue'
import DriverScheduleComponent from '@/components/pages/driver_schedule.vue'
import { configType } from '~/models/config_driver_app'
import { firebaseApp } from '~/plugins/firestore'
import { drivingMode, driving } from '~/components/common/driving'
import NewMessage from '@/components/new_message.vue'
import InformationComponent from '@/components/pages/information.vue'

export default createComponent({
  components: {
    ButtunOperatorComponent,
    CarSelectClearCompornent,
    DailyReportComponent,
    DriverScheduleComponent,
    InformationComponent,
    'cti-new-message': NewMessage
  },
  setup(_props, context: SetupContext) {
    const showSessionErrMessage = ref<Boolean>(false)
    context.root.$nuxt.$on(
      'showSessionErrMessage',
      (isSessionErr: boolean = true) => {
        showSessionErrMessage.value = isSessionErr
      }
    )
    const dialogOpen = ref<boolean>(false)
    const menuDrawer = ref<boolean>(false)
    const fromMapMenuDrawer = computed(() => {
      return context.root.$store.state.fromMapMenuDrawer
    })
    watch(fromMapMenuDrawer, () => {
      menuDrawer.value = false
    })

    const getConfigDriverApp = async () => {
      let config_maptype = 'roadmap'
      let config_headingup = false
      await context.root.$axios
        .$get(`/config_driver_app/me`)
        .then((res: any) => {
          if (res && res.length > 0) {
            res.forEach((r: any) => {
              if (r.config_key === configType.MAPTYPE)
                config_maptype = r.config_value
              if (r.config_key === configType.HEADINTGUP)
                config_headingup = r.config_value.toLowerCase() === 'true'
            })
          }
        })
        .catch((err: any) => {
          console.log(err)
          context.root.$nuxt.$emit('showSessionErrMessage', true)
        })
      context.root.$store.commit('updateMapType', {
        maptype: config_maptype
      })
      context.root.$store.commit('updateSetHeadingUp', {
        isHeadingUp: config_headingup
      })
    }
    // const isLoggedIn = context.root.$auth.loggedIn
    const isLoggedIn = context.root.$auth.loggedIn

    if (!isLoggedIn) {
      context.root.$router.push({
        path: '/login/'
      })
    } else {
      getConfigDriverApp()
    }
    const user: any = computed(() => {
      return context.root.$auth.user
    })
    const user_id = ref<number>(user.value.id || '')
    const vehicleID = computed(() => {
      return context.root.$store.state.vehicleID
    })
    const userName = user.value.username
    const overlay = ref<boolean>(false)
    const showCarSelectClear = ref<boolean>(false)
    const showDailyReport = ref<boolean>(false)
    const showDriverSchedule = ref<boolean>(false)
    const showInformation = ref<boolean>(false)
    const vehicleStatus = computed(() => {
      return context.root.$store.state.vehicleStatus
    })
    const formatDate = ref<string>('')
    const timer = setInterval(() => {
      formatDate.value = moment().format('YYYY/MM/DD HH:mm')
      // セッションが切れたら自動ログアウトする
      if (!context.root.$auth.loggedIn) {
        context.root.$nuxt.$emit('showSessionErrMessage', true)
      }
    }, 1000)
    onBeforeUnmount(() => {
      clearInterval(timer)
      logout()
    })
    const daily_report = () => {
      context.root.$router.push('/daily_report/')
    }
    const driver_schedule = () => {
      context.root.$router.push('/driver_schedule/')
    }
    const carbreak = () => {
      overlay.value = true
      const param = [
        {
          op: 'replace',
          path: '/status_rest',
          value: true
        },
        {
          op: 'replace',
          path: '/current_driver',
          value: user_id.value
        }
      ]
      context.root.$axios
        .$patch(`/vehicles/${vehicleID.value}`, param)
        .then(() => {
          context.root.$store.commit('updateStatusVehicle', {
            vehicleStatus: 'REST'
          })
        })
        .catch((err: any) => {
          console.log(err)
          context.root.$nuxt.$emit('showSessionErrMessage', true)
        })
    }
    const carbreakfinish = () => {
      overlay.value = false
      const param = [
        {
          op: 'replace',
          path: '/status_active',
          value: true
        },
        {
          op: 'replace',
          path: '/current_driver',
          value: user_id.value
        }
      ]
      context.root.$axios
        .$patch(`/vehicles/${vehicleID.value}`, param)
        .then((res: any) => {
          context.root.$store.commit('updateStatusVehicle', {
            vehicleStatus: 'PREACTIVE'
          })
          context.root.$store.commit('updateDateSelected', {
            date_selected: res.date_selected
          })
        })
        .catch((err: any) => {
          console.log(err)
          context.root.$nuxt.$emit('showSessionErrMessage', true)
        })
    }
    const maptype = computed(() => {
      return context.root.$store.state.maptype
    })
    const changeMapType = () => {
      const config_maptype =
        maptype.value === configType.MAPTYPE_ROADMAP
          ? configType.MAPTYPE_HYBRID
          : configType.MAPTYPE_ROADMAP
      context.root.$store.commit('updateMapType', {
        maptype: config_maptype
      })
      context.root.$axios
        .$patch(
          `/config_driver_app/me?config_key=${configType.MAPTYPE}&config_value=${config_maptype}`
        )
        .catch((err) => {
          console.log('Request for ChangeMapType failed. (%o)', err)
          context.root.$nuxt.$emit('showSessionErrMessage', true)
        })
    }
    const isHeadingUp: any = computed(() => {
      return context.root.$store.state.isHeadingUp
    })
    const setHeadingUp = () => {
      context.root.$store.commit('updateSetHeadingUp', {
        isHeadingUp: !isHeadingUp.value
      })
      context.root.$axios
        .$patch(
          `/config_driver_app/me?config_key=${configType.HEADINTGUP}&config_value=${isHeadingUp.value}`
        )
        .catch((err) => {
          console.log('Request for setHeadingUp failed. (%o)', err)
          context.root.$nuxt.$emit('showSessionErrMessage', true)
        })
    }
    const done_drivestop = ref<Boolean>(false)
    const routeback = () => {
      const param = [
        {
          op: 'replace',
          path: '/current_order',
          value: null
        },
        {
          op: 'replace',
          path: '/order_type',
          value: null
        },
        {
          op: 'replace',
          path: '/status_active',
          value: true
        }
      ]
      context.root.$axios
        .$patch(`/vehicles/${vehicleID.value}`, param)
        .then((res: any) => {
          context.root.$store.commit('updateStatusVehicle', {
            vehicleStatus: 'ACTIVE'
          })
          context.root.$store.commit('updateDateSelected', {
            date_selected: res.date_selected
          })
          done_drivestop.value = false
        })
        .catch((err: any) => {
          console.log(err)
          context.root.$nuxt.$emit('showSessionErrMessage', true)
        })
    }
    const isMode = ref<string>(process.env.ctiEnv)
    const isDrivingMode = ref<boolean>(driving.isDriving())
    const isDrivingPlay = ref<boolean>(driving.isPlay())
    const isSpeed = ref<number>(driving.getSpeed())
    const changeDrivingSpeed = (val: string) => {
      val === 'Up' ? driving.fastSpeed() : driving.slowSpeed()
      isSpeed.value = driving.getSpeed()
    }
    const drivingPlay = () => {
      isDrivingPlay.value = isDrivingPlay.value
        ? driving.setStop()
        : driving.setPlay()
    }
    context.root.$nuxt.$on('changeDrivingMode', (val: boolean) => {
      isDrivingMode.value = driving.changeMode(val)
    })
    context.root.$nuxt.$on('logout', () => {
      logout()
    })
    const logout = async () => {
      if (vehicleID.value) {
        const selectVehicle = [
          {
            op: 'replace',
            path: '/current_driver',
            value: null
          },
          {
            op: 'replace',
            path: '/status_inactive',
            value: true
          }
        ]
        await context.root.$axios
          .$patch(`/vehicles/${vehicleID.value}`, selectVehicle)
          .then(() => {
            context.root.$store.commit('updateCurrentVehicleId', {
              vehicleID: ''
            })
            context.root.$store.commit('updateCurrentVehicleName', {
              vehicleName: ''
            })
            context.root.$store.commit('updateStatusVehicle', {
              vehicleStatus: 'INACTIVE'
            })
          })
          .catch((err: any) => {
            console.log(err)
            // context.root.$nuxt.$emit('showSessionErrMessage', true)
          })
      }
      await context.root.$auth.logout().catch(console.error)
      await firebaseApp
        .auth()
        .signOut()
        .catch(console.error)
      await context.root.$router.push('/login/')
      showSessionErrMessage.value = false
    }
    const checkLoginSameUser = async () => {
      const param = {
        app_type: 'navi_app'
      }
      const params = new URLSearchParams(param)
      await context.root.$axios
        .$post(`/auth/check_login_same_user`, params)
        .then((res) => {
          dialogOpen.value = res.result
        })
        .catch((err: any) => {
          console.log(err)
          context.root.$nuxt.$emit('showSessionErrMessage', true)
        })
    }
    if (context.root.$auth.loggedIn) {
      checkLoginSameUser()
    }
    const noSleep = new NoSleep()

    // firebaseの認証されていない場合は、動かさないようにする
    const auth = firebaseApp.auth()
    auth.onAuthStateChanged((user) => {
      if (user) {
        // 対象のメッセージをバインドする
        context.root.$store.dispatch('message/bindMessages')
      }
    })
    onMounted(async () => {
      await noSleep.enable()
    })
    return {
      drivingMode,
      isMode,
      isDrivingMode,
      isDrivingPlay,
      isSpeed,
      changeDrivingSpeed,
      drivingPlay,
      formatDate,
      userName,
      menuDrawer,
      daily_report,
      driver_schedule,
      vehicleStatus,
      carbreak,
      carbreakfinish,
      overlay,
      changeMapType,
      routeback,
      done_drivestop,
      maptype,
      setHeadingUp,
      isHeadingUp,
      logout,
      showCarSelectClear,
      showDailyReport,
      showDriverSchedule,
      showInformation,
      timer,
      dialogOpen,
      isLoggedIn,
      showSessionErrMessage
    }
  }
})
